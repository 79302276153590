/*===================================
=                                   =
=  - !Hello There                   =
=  - this is Your working space     =
=  - here you can customize phenix  =
=  - to your app ui design needs    =
=  - Good Luck :)                   =
=                                   =
===================================*/

/*====> Import Phenix <====*/
import Phenix from '.';

//======> D.O.M is Ready [for you] <=======//
Phenix(document).ready((event:any) => {
    //===> Sticky Elements <===//
    Phenix("[data-sticky]").sticky();

    //===> Responsive Menu <===//
    Phenix(".px-navigation").menu();

    //===> Sliders <===//
    Phenix(".px-slider").slider();

    //===> Multimedia <===//
    Phenix(".px-media").multimedia();

    //===> Animations <===//
    Phenix(".px-animate").animations({
        animateCSS: ["fading", "sliding", "attentions", "zooming", "utilities"],
    });

    //===> Custom Scrollbar <===//
    Phenix("html").addClass("px-scrollbar");

    //===> Popups <===//
    Phenix('.px-modal').popup();

    //===> Popups <===//
    Phenix('.px-tabs').tabs();

    //===> Smoth-Scroll <===//
    Phenix('a[href^="#"]').smothScroll();

    //===> Animated Number <===//
    Phenix('.animated-number').counter({lazyloading: true, duration: 2500});

    //===> Phenix Select <===//
    Phenix('.px-select').select();

    //===> Phenix Uploader <===//
    Phenix('.px-uploader').uploader();

    //====> Progress <====//
    Phenix('.px-progress').progress();

    //====> View Toggle <====//
    Phenix(".view-btns [data-view]").on("click", (isClicked:any) => {
        //===> Data <===//
        let target:HTMLElement = isClicked.target,
            targetParent = Phenix(target).ancestor(".view-btns"),
            viewList = Phenix(targetParent).next(".view-list"),
            viewMode = target.getAttribute("data-view");
            
        //===> Set Active <===//
        let currentActive = Phenix(target).addClass("px-active").siblings(".px-active");
        Phenix(...currentActive).removeClass("px-active");

        //===> View Change <===//
        if (viewList && viewMode === "list") Phenix(viewList).addClass("list-mode");
        else Phenix(viewList).removeClass("list-mode");      
    });

}).utilities();